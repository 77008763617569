import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/256aa59e/src/components/default.mdx.js";
import Layout from "../../components/posts.mdx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <p>{`This is the content of the page! You can write anything here. You can:`}</p>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul">{`Make lists`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`make todo lists`}<ul parentName="li" {...{
          "className": "contains-task-list"
        }}>
          <li parentName="ul" {...{
            "className": "task-list-item"
          }}><input parentName="li" {...{
              "type": "checkbox",
              "checked": true,
              "disabled": true
            }}></input>{` `}{`You can even nest them!`}</li>
          <li parentName="ul"><img parentName="li" {...{
              "src": "https://c2.staticflickr.com/8/7173/6760590389_68f0028b5f_b.jpg",
              "alt": "alt text"
            }}></img></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/posts/two"
            }}>{`You can link other posts!`}</a></li>
        </ul></li>
    </ul>
    <p>{`You can even use video!`}</p>
    <Video vid="BV3FcAecXL0" mdxType="Video" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      